<template>
  <div>
    <div v-if="isLogin">
      <ToeflList />
    </div>
    <div v-else class="toefl-container toefl-home">
      <div class="row page page0">
        <div class="col-md-4 col-sm-12 col-xs-12"></div>
        <div class="col-md-4 col-sm-12 col-xs-12 right">
          <div class="title">
            <h1>
              <QuestionCount :endAdornment="`+ FREE Questions.`" />
            </h1>
          </div>
          <div class="title">
            <p>Start practicing now.</p>
          </div>
          <div v-if="!isLogin">
            <div class="toefl-button" style="margin-bottom:20px">
              <a href="/toefl/login">
                <el-button type="success" style="width:100%">
                  Login
                </el-button>
              </a>
            </div>
            <div class="toefl-button">
              <a href="/toefl/register">
                <el-button style="width:100%">Register</el-button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- page1 -->
      <div class="row page page1 text-center">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="page-title" v-html="$t('home.page1-title')"></div>
          <div class="page-content" v-html="$t('home.page1-content1')"></div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="img">
            <img
              src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page1-TestAdmit.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- page2 -->
      <div class="row page page2">
        <div class="col-md-6 col-xs-12">
          <div class="page-title" v-html="$t('home.page2-title')"></div>
          <div class="page-content" v-html="$t('home.page2-content1')"></div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="img">
            <img
              v-if="lang.value === 'EN-US'"
              src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page2-TestAdmit.png"
              alt=""
            />
            <img
              v-else
              src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page2-TestAdmit.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- page3 -->
      <div class="row page page3">
        <div class="col-md-6 col-xs-12">
          <div class="page-title" v-html="$t('home.page3-title')"></div>
          <div class="page-content" v-html="$t('home.page3-content1')"></div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="img">
            <img
              src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page3-TestAdmit.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- page4 -->
      <div class="row page page4">
        <div class="col-md-6 col-xs-12">
          <div class="page-title" v-html="$t('home.page4-title')"></div>
          <div class="page-content" v-html="$t('home.page4-content1')"></div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="img">
            <img
              src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page4-TestAdmit.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- page5 -->
      <div class="row page page5">
        <div class="col-md-6 col-xs-12">
          <div class="page-title" v-html="$t('home.page5-title')"></div>
          <div class="page-content" v-html="$t('home.page5-content1')"></div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="img">
            <img
              src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page5.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- page6 -->
      <div class="row page page7" style="height:auto;min-height: auto">
        <div class="col-sm-6 col-xs-12">
          <div class="page-title">
            Start Practicing for free.
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row" style="margin:0;line-height:54px" v-if="!isLogin">
            <div class="toefl-button col-md-6">
              <router-link :to="{ name: 'Login' }" style="width:100%">
                <el-button type="success" style="width:100%">
                  Login
                </el-button>
              </router-link>
            </div>
            <div class="toefl-button col-md-6">
              <router-link :to="{ name: 'Register' }" style="width:100%">
                <el-button style="width:100%">Register</el-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row page page8" v-if="false">
        <div class="">
          <div class="row">
            <div
              class="col-sm-4"
              v-for="(passage, index) in blogList"
              :key="index"
            >
              <a :href="passage.url" class="passage-box">
                <div class="header">
                  <img alt="img" :src="passage.img" width="" />
                </div>
                <div class="footer">
                  <h4 class="title">
                    {{ passage.title }}
                  </h4>
                  <p class="passage-info">
                    {{ passage.content }}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import ToeflList from "@/views/toefl/toeflList/Index.vue";
import QuestionCount from "@/views/toefl/questionCount/Index.vue";

export default {
  metaInfo() {
    return {
      title: "Home - " + this.CompanyName
    };
  },
  components: {
    ToeflList,
    QuestionCount
  },
  mixins: [],

  props: [],
  data() {
    return {
      blogList: [
        {
          title: "About the TOEFL iBT® Test",
          content:
            "The TOEFL iBT® test helps you stand out confidently in English, and it comes with a clear advantage — universities know you are ready to succeed.That's why it's accepted by more than 11,500 universities and other institutions in over 160 countries, including Australia, Canada, New Zealand, the United States, the U.K., and across Europe and Asia.",
          url: "https://www.ets.org/toefl/test-takers/ibt/about",
          img:
            "https://vclass-cdn.voicetube.com/assets/img/courses/college-essays/you-will-learn.webp"
        },
        {
          title: "How ETS Scores the TOEFL iBT® Test",
          content:
            "An important part of your TOEFL preparation is calculating the score you need to enter into your preferred university. Follow this easy explanation to calculate your score in each section of the TOEFL. ",
          url:
            "https://www.ets.org/toefl/score-users/scores-admissions/how-ets-scores-toefl-ibt-transcript",
          img:
            "https://vclass-cdn.voicetube.com/assets/img/courses/college-essays/feature.webp"
        },
        {
          title: "TOEFL Preparation - How To Calculate The Score You Need",
          content:
            "All of the TOEFL iBT scoring guides are holistic rubrics, which means that the level of performance is determined by assessing performance across multiple criteria as a whole. The question we're trying to answer is: Across these dimensions, how well did the test taker complete the task? This is in contrast to an analytic rubric, which articulates levels of performance for each criteria.",
          url:
            "https://www.ets.org/toefl/score-users/scores-admissions/how-ets-scores-toefl-ibt-transcript",
          img:
            "https://vclass-cdn.voicetube.com/assets/img/courses/college-essays/you-will-learn.webp"
        }
      ]
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.toefl-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.page-title {
  font-size: 50px;
  line-height: 65px;
  font-weight: 700;
  /* text-align: center; */
  margin-bottom: 40px;
  /* word-break: break-all !important;
  word-wrap: break-word !important; */
  width: 100%;
}
.page1 .page-title {
  margin-bottom: 20px;
}
.page-content {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  /* word-break: break-all !important;
  word-wrap: break-word !important; */
}
.page .img {
  text-align: center;
}
.page .img img {
  max-height: 550px;
  max-width: 100%;
}
.page1,
.page3,
.page5 {
  background: #f1faf7;
}
.page6 {
  text-align: center;
}
.page6 a {
  display: block;
}
.page6 .img {
  padding: 30px;
}

.page6 .img img {
  width: 100%;
  max-height: 300px;
  border: 4px solid #efefef;
}
.page3,
.page5 {
  flex-direction: row-reverse;
}
.page7 {
  background: #ebebeb;
}
.toefl-welcome {
  /* margin-bottom: 15px; */
}
.toefl-home {
  /* padding-top: 20px; */
}
.toefl-img {
  border: 2px solid var(--themeColor);
  padding: 20px;
}

.title {
  font-size: 40px;
  line-height: 55px;
  font-weight: 700;
  color: var(--themeColor);
  text-align: center;
}
.title {
  margin-bottom: 40px;
}
.title h1 {
  font-weight: 700;
  color: var(--themeColor);
  margin-bottom: 0;
}
.toefl-button {
  margin: 30px 0 0 0;
  /* padding: 0 20px; */
}
.page .toefl-button {
  margin: auto 0;
  /* padding: 0 20px; */
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.page8.footer {
  background-color: #f3f3f1;
  color: rgba(0, 0, 0, 0.65);
}
.page.page8 {
  min-height: auto;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.65);
}
.box-header {
  margin-bottom: 40px;
}
.passage-box {
  display: inline-block;
  text-decoration: none;
  margin: 20px 20px 0 20px;
  width: 100%;
  color: rgba(0, 0, 0, 0.43) !important;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.passage-box:hover {
  transform: translateY(-6px);
}
.passage-box .header {
  height: 220px;
  overflow: hidden;
}
.passage-box .header img {
  display: block;
  width: 100%;
  height: 100%;
}
.footer .title {
  text-align: left;
  width: 100%;
  line-height: 1.5em;
  max-height: 68px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  margin: 10px 0;
  font-size: 24px;
}
.passage-info {
  margin: 20px 0;
  height: 200px;
}
@media screen and (min-width: 1080px) {
  .toefl-container {
    padding: 0;
  }
  .passage-box .header {
    height: 320px;
    overflow: hidden;
  }
  .page {
    padding: 40px 10%;
    /* text-align: center; */
    min-height: calc(100vh - 100px);
    border-top: 1px solid #efefef;
  }
  .page0 {
    min-height: auto;
    padding: 40px 60px;
    border: none;
  }
  .page > .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    flex-direction: column;
  }
  .page7 .page-title {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 1080px) {
  .title {
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
    color: var(--themeColor);
    text-align: center;
    margin-top: 20px;
  }
  .title h1 {
    font-size: 30px;
  }
  .toefl-container {
    padding: 15px;
  }
  .toefl-button {
    margin: 30px 0 20px 0;
  }
  .title {
    margin-bottom: 0;
  }
  .page {
    padding: 40px 20px;
    border-top: 1px solid #efefef;
  }
  .page-title {
    font-size: 25px;
    line-height: 1.125;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    word-break: unset !important;
  }
  .page1 .page-title {
    margin-bottom: 30px;
    text-align: center;
  }
  .page2.page .img img {
    width: 80%;
  }
  .page-content {
    font-size: 19px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 20px;
    word-break: unset !important;
  }
  .page .img {
    text-align: center;
    width: 100%;
  }
  .page .img img {
    height: auto;
    width: 100%;
  }
  .page5 {
    background: #f1faf7;
  }

  .page6 {
    text-align: center;
  }
  .page6 a {
    display: block;
  }
  .page6 .img {
    padding: 15px 0;
  }

  .page6 .img img {
    width: 100%;
    max-height: 300px;
    border: 4px solid #efefef;
  }
  .page3,
  .page5 {
    flex-direction: row-reverse;
  }
  .page7 {
    background: #ebebeb;
  }
}
@media screen and (max-width: 400px) {
  /* .toefl-home {
    padding: 3.2vw;
  } */
  .toefl-button {
    padding: 0;
  }
  .toefl-container {
    padding: 15px;
  }
  .toefl-container {
    padding-bottom: 0;
  }
}
</style>
